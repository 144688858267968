var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CyDataTableYdApi',{attrs:{"fetch-available":{ keyPath: 'quotas' },"filters":_vm.$static.filters,"groups":_vm.$static.groups,"headers":_vm.$static.headers,"key-field":"rowKey","paginate":false,"parse-items-array":_vm.quotasParser,"searchable-fields":_vm.$static.searchableFields,"transform-items":function (item) { return (Object.assign({}, item, {usage: _vm.getUsagePercentage(item)})); }},scopedSlots:_vm._u([{key:"group-header-title-append",fn:function(ref){
var props = ref.props;
return [(_vm._.some(props.items, function (ref) {
	var usage = ref.usage;

	return usage >= 90;
}))?_c('v-icon',{staticClass:"ml-1",attrs:{"color":"error","size":"18"}},[_vm._v(" error ")]):_vm._e(),(_vm._.some(props.items, function (ref) {
	var usage = ref.usage;

	return _vm._.inRange(usage, 70, 90);
}))?_c('v-icon',{staticClass:"ml-1",attrs:{"color":"warning","size":"18"}},[_vm._v(" warning ")]):_vm._e()]}},{key:"group-header-actions",fn:function(ref){
var props = ref.props;
return [(_vm._.head(props.groupBy).startsWith('team'))?_c('CyButton',{staticClass:"edit-quota__btn my-n1 mr-1",attrs:{"icon":"edit","icon-only":"","sm":"","theme":"primary","variant":"tertiary","to":{ name: 'quota', params: { quotaId: _vm._.head(props.items).id } }},on:{"click":function($event){$event.stopPropagation();}}}):_vm._e()]}},{key:"table-cmp-body-row",fn:function(ref){
var props = ref.props;
return [_c('td',[_vm._v(" "+_vm._s(_vm.$t(("quotas." + (props.item.type))))+" ")]),_c('td',[_vm._v(" "+_vm._s(props.item.team)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.item.resource_pool)+" ")]),_c('td',{staticClass:"d-flex align-center"},[_c('v-progress-linear',{attrs:{"value":props.item.usage,"color":_vm.getUsageBarColor(props.item),"background-color":"grey lighten-2","height":"8","rounded":""}}),_c('div',{staticClass:"usage-percentage"},[_vm._v(" "+_vm._s(props.item.usage)+"% ")]),_c('div',{staticClass:"usedOf flex-grow-1"},[_vm._v(" "+_vm._s(_vm.getUsedOfText(props.item, true))+" ")]),_c('CyMenu',{attrs:{"items":_vm.getRowActionItems(props.item),"bottom":true,"offset-y":true,"left":true}})],1)]}}])},[_c('template',{slot:"table-cmp-no-data"},[_c('div',{staticClass:"no-data d-flex flex-column justify-center align-center"},[_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('noQuotasFound'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('tryDifferentFilters'))+" ")])])]),_c('template',{slot:"table-cmp-header-actions"},[_c('CyButton',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:('CreateQuota'),expression:"'CreateQuota'"}],staticClass:"create-quota__btn",attrs:{"icon":"add","theme":"secondary","variant":"primary","to":{ name: 'newQuota' }}},[_vm._v(" "+_vm._s(_vm.$t('quotas.createQuota'))+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }